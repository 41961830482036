import mixin from './../plugins/mixin';
class SocketService {
	wsUrl = mixin.methods.config('wsUrl')
	socket = null;
	connected = false;
	connect(){
		console.log('try connect');
		return new Promise(function(resolve, reject) {
			let socketUser = JSON.parse(localStorage.getItem('user'));
			this.socket = new WebSocket(this.wsUrl+"?token="+socketUser.accessToken);
			this.socket.onopen = function() {
	            resolve(this.socket);
	    		console.log('tconnect success');

	            this.connected = true;
	        }.bind(this);
	        this.socket.onerror = function(err) {
	            reject(err);
	        };
	    }.bind(this));
	/*	let socketUser = JSON.parse(localStorage.getItem('user'));
		if (socketUser && socketUser.accessToken) {
			  	console.log(this.wsUrl+"?token="+socketUser.accessToken);
				this.socket = new WebSocket(this.wsUrl+"?token="+socketUser.accessToken);
				
				this.socket.onopen = this.onopen			
				this.socket.onerror = this.onerror	
		  }
		  else{
			 return false; 
		  }*/
    }
	close(){
		
		this.socket.close();
		this.socket = null; // prevent memory leak
   
	}
	
	onmessage(func){
		this.socket.onmessage = func;
	}
	sendMessage(msg){
		this.socket.send(JSON.stringify(msg));
	    
	}
	

}
export default new SocketService();