<template>
    <PageHeaderLogged title="Мои места" menu="false" @updatelocation="handleUpdateLocation"/>
    <div class="icon i" @click="showInfo"></div>  
      	  <div class="wrapper graybg chatlist_wrapper minheightwrapper slimleftright placese_view">
  	<div class="emptlist  emptlistplaces" v-show="emptyshow">Список мест  пуст</div>
      	   	<div class="placelist"><PlaceItem v-for="place in places" :place="place" /></div>
 
  </div>  
       <BottomPanel activeplaces="active"/>

<modal-window ref="info">
		<template v-slot:title>
                <h3 class="modal-title">Справка</h3>
        </template>
 		<template v-slot:body>Текст справки</template>
 	    <template v-slot:footer-okbtn>
        </template>
	</modal-window>
</template>

<script>
import ModalWindow from '../components/modal-window.vue'

export default {
  name: 'PlacesView',
  props: {
    msg: String
  },
   components: {
       ModalWindow
        },
  data() {
    	return {
    	  places:[],
    	loading : true,
  		  errorStr:null,
  		  emptyshow:false,
		}
	},
	created() {
    	this.getPlacesList();
  },
  mounted(){
  		this.setHeight('chatlist_wrapper');
  
  },
	methods: {
	showInfo(){
		this.$refs.info.show=true;
	},
	handleUpdateLocation(success){
		    	this.getPlacesList();
			
	},
    getPlacesList(){
    		
    		this.$store.dispatch("list/getPlacesList").then(
	        (data) => {
	            this.loading = false;
	            this.places = data;
	            this.emptyshow = this.places.length == 0;
            },
	        (error) => {
	        	
	          this.loading = false;
	        }
	 );
    
    },
    }
}
</script>
    