import axios from 'axios';
import mixin from './../plugins/mixin';

class MapService {
	  key = mixin.methods.config('ySearchApiKey');
      suggest(params){
		let mapParams = {'lang':'ru_RU','type':'biz','text':params.adress,'spn':"0.02,0.02",'rspn':1};
		if (params.coord!=undefined)  mapParams.ll = params.coord[0] +','+params.coord[1];
			if (params.adress == undefined){
				mapParams.text = params.coord[0] +','+params.coord[1];
		}
		if (mapParams.text == '') return;
		return axios({
				method: 'get',
				url:'https://search-maps.yandex.ru/v1/?apikey='+this.key, 
				params:mapParams,
				}).then(response => {
					if (response.status == 200 && response.data.features.length>0) {
			            if (params.coord!=undefined) {
							
			            	for(let i = 0;i<response.data.features.length;i++) {
			            		let meters = this.getDistanceFromLatLonInMeters(response.data.features[i].geometry.coordinates[0],response.data.features[i].geometry.coordinates[1],params.coord[0],params.coord[1]);
			            		if (meters<50) return response.data.features[i];
							}
							return;
						}
//						alert(response.data.features[0].geometry.coordinates[0]);
//			            alert(response.data.features[0].geometry.coordinates[1]);
			            
						return response.data.features[0];
			        }
		          
		     // }
		      });;	
			
		/*$.get('https://search-maps.yandex.ru/v1/?apikey=c3de2e83-690c-485a-b0ab-d3e4b8de8cd4',mapParams,function(answer){
			if (answer.features.length > 0){
				return answer.features[0];
			}
		})*/
		
	}
    getDistanceFromLatLonInMeters(lat1,lon1,lat2,lon2) {
		  var R = 6371; // Radius of the earth in km
		  var dLat = (lat2-lat1) *  (Math.PI/180);  // deg2rad below
		  var dLon = (lon2-lon1) *  (Math.PI/180); 
		  var a = 
		    Math.sin(dLat/2) * Math.sin(dLat/2) +
		    Math.cos(lat1*  (Math.PI/180)) * Math.cos(lat2 *  (Math.PI/180)) * 
		    Math.sin(dLon/2) * Math.sin(dLon/2)
		    ; 
		  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
		  var d = R * c * 1000; // Distance in km
		  return d;
	} 
}
export default new MapService();