import { createStore } from "vuex";
import { auth } from "./auth.module";
import { user } from "./user.module";
import { info } from "./info.module";
import { list } from "./list.module";
import { socket } from "./socket.module";
import { map } from "./map.module";

const unreadedMessages = localStorage.getItem('unreadedMessages');

const store = createStore({
  modules: {
    auth,
    user,
    info,
    list,
    socket,
    map
     
  },
  state: {
	  unreadedMessages:unreadedMessages,
	  onMessageSubscribe:false,
	  updateObject:Object,
  },
  getters: {
	  getUnreadedMessages (state) {
	      return state.unreadedMessages;
	    }
  },
  mutations: {
	    setUnreaded (state, count) {
	      state.unreadedMessages = count;
	      localStorage.setItem('unreadedMessages', count);
	    },
	    setUpdateObject (state, updateObject) {
		      state.updateObject = updateObject;
		      //localStorage.setItem('unreadedMessages', count);
		},
	  
	  }
});

export default store;
