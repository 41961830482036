import InfoService from '../services/info.service';


export const info = {
  namespaced: true,
  actions: {
    get({ commit }, id) {
      return InfoService.getinfo(id).then(
        info => {
     //     commit('profileSuccess', user);
          return Promise.resolve(info);
        },
        error => {
        	
          commit('getInfoFailure',error.request.status);
          return Promise.reject(error);
        }
      );
    },
  },



  mutations: {
	  getInfoFailure(state, code) {
		  if (code == 401) {
		        state.status.auth.loggedIn = false;
		        state.status.user = null;
		    //    localStorage.removeItem('user');	
	  
		  }
    },
	 profilSuccess(state, user) {
    },
  }
};
