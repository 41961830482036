<template>
    <div v-if="show" class="modal-shadow" @click.self="closeModal">
        <div v-if="show" class="modalx">
         <!--   <div class="modal-close" @click="closeModal">X</div> -->
            <slot name="title">
                <h3 class="modal-title">Заголовок</h3>
            </slot>
      <div class="popup_body">      <slot name="body">
                <div class="modal-contentx">
                    Дефолтный контент модального окна
                </div>
            </slot>
</div>                <div class="modal-footerx">
                
            <slot name="footer-okbtn">
                    <button class="modal-footer__button" @click="closeModal">    Ок  </button>
            </slot>
                    <button class="modal-footer__button modal-footer__button_invert" @click="closeModal">    Закрыть  </button>
                </div>
        </div>
    </div>
</template>
 
<script>
    export default {
        name: "ModalWindow",
        data: function () {
            return {
                show: false
            }
        },
        methods: {
            closeModal: function () {
                this.show = false
            }
        }
    }
</script>
 

