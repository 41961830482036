import ListService from '../services/list.service';
import SocketService from '../services/socket.service';


export const list = {
  namespaced: true,
  actions: {
    getProfiles({ commit }, filter) {
      return ListService.getProfiles(filter).then(
        data => {
     //     commit('profileSuccess', user);
          return Promise.resolve(data);
        },
        error => {
        	
          commit('getProfilesFailure',error.request.status);
          return Promise.reject(error);
        }
      );
    },
    getChat({ commit }, uuid) {
        return ListService.getChat(uuid).then(
          data => {
       //     commit('profileSuccess', user);
            return Promise.resolve(data);
          },
          error => {
          	
            commit('getProfilesFailure',error.request.status);
            return Promise.reject(error);
          }
        );
      },
    getChatList({ commit }) {
          return ListService.getChatList().then(
            data => {
         //     commit('profileSuccess', user);
              return Promise.resolve(data);
            },
            error => {
            	
              commit('getProfilesFailure',error.request.status);
              return Promise.reject(error);
            }
          );
        },
    getPlacesList({ commit }) {
            return ListService.getPlacesList().then(
              data => {
           //     commit('profileSuccess', user);
                return Promise.resolve(data);
              },
              error => {
              	
                commit('getProfilesFailure',error.request.status);
                return Promise.reject(error);
              }
            );
          },
    setReaded({ commit }, message){
            return ListService.setReaded(message).then(
                    data => {
                      return Promise.resolve(data);
                    },
                    error => {
                      commit('sendMessageFailure',error.request.status);
                      return Promise.reject(error);
                    }
                  );
    },
    sendMessage({ commit }, message) {
    	
    	return ListService.sendMessage(message).then(
            data => {
            	SocketService.sendMessage({action:'MessageToUser', 'info':message});
            	return Promise.resolve(data);
            },
            error => {
            	
              commit('sendMessageFailure',error.request.status);
              return Promise.reject(error);
            }
          );
        },
    checkUpdates({ commit }) {
            return ListService.checkUpdates().then(
              data => {
           //     commit('profileSuccess', user);
                return Promise.resolve(data);
              },
              error => {
              	
                commit('sendMessageFailure',error.request.status);
                return Promise.reject(error);
              }
            );
          },
  },



  mutations: {
	  getInfoFailure(state, code) {
		  if (code == 401) {
		        state.status.loggedIn = false;
		        state.status.user = null;
		    //    localStorage.removeItem('user');	
	  
		  }
    },
	 listServiceSuccess(state, user) {
    },
  }
};
