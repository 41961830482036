
<template>
<div class="imageuploader " :class="[type, 'imageuploader' +  sort]"  ref="divcontainer" v-on:click="openPopup"> <div class="divloader" v-show="loading"></div> <div v-show="showdelete"  class="remove__photo" v-on:click="deleteFile"></div> <div v-show="showplus" class="plus"> + </div> </div>
        <input type="file" accept="image/*" style="display:none;" id="file" ref="file" v-on:change="handleFileUpload()" />
        

<modal-window ref="modal">
<template v-slot:title>
                <h3 class="modal-title">Внимание</h3>
            </template>
 <template v-slot:body>Ошибка загрузки фото</template>
 
    <template v-slot:footer-okbtn>
    &nbsp;
            </template>
       
 
</modal-window>
<div class="popup_menu_wrapper" v-show="popupmenu">
<div class="popup_menu " :class="['popup_menu' +  sort]">
	
</div>
	<div class="links">
	<a href="#" v-show="showUpload"  v-on:click="openFile">Загрузить</a>
	<a href="#" v-show="showChange" v-on:click="openFile">Изменить</a>
	<a href="#" v-show="showDelete" v-on:click="deleteFile">Удалить</a>
	<a href="#" v-show="showMain" v-on:click="makeMain">Сделать главной</a>
	<a href="#" v-on:click="closeMenu">Отмена</a>
	</div>
</div>
</template>


<script>
import ModalWindow from '../components/modal-window.vue'

export default {
	props : ['type','sort','image'],
    name: 'Photo',
    
    components: {
       ModalWindow
    },
    data(){
      return {
          file: '',
          loading: false,
          showplus:true,
          id:-1,delclick : false,popupmenu:false,
          showUpload:true,
          showChange:false,
          showDelete:false,
          showMain:false,
          
      }
    },
    methods: {
    openPopup()
    {
    	this.popupmenu = true;
    },
    closeMenu(){
    	this.popupmenu = false;
    	
    },
    openFile(){
    if (!this.delclick)
    	$(this.$refs.file).trigger('click');
    	this.closeMenu();
    },
    getControl(){
    return $(".imageuploader"+this.sort);
    },
    formatImage(format){
    	this.getControl().css({'background-image':'url('+(this.sort == 0 ? format.m : format.s)+')', 'background-size':'cover'});
    	
    	this.showDelete = true;
    	this.showUpload = false;
    	this.showChange = true;
    	if (this.sort>0)
	    	this.showMain = true;
    	
    	this.showplus =false;
    	this.id = format.id;
    	//this.getControl().html('<div  class="remove__photo" ref="xxx" v-on:click="deleteFile()"></div>');
    },
    makeMain(){
    	this.loading = true;
	    this.$store.dispatch("user/changePhotoToMain", this.id).then(
	        (answer) => {
 	           this.loading = false;
	         if (answer.success){
           			window.location.reload(true);	
	           }
	         },
	         (error) => {
	          this.loading = false;
	        }
	 );
    },
    deleteFile(event){
     this.closeMenu();
     
	 event.preventDefault();
      this.loading = true;
	    this.$store.dispatch("user/deletePhoto", this.id).then(
	        (answer) => {
 	           this.loading = false;
	           if (answer.success){
           			this.getControl().css({'background':'white'});
    			this.showplus =true;
   				this.showDelete = false;
		    	this.showUpload = true;
    			this.showChange = false;
		    	this.showMain = false;
    
	           }
	           else{
	            	this.$refs.modal.show = true
 
	           }
	        },
	        (error) => {
	          this.loading = false;
	        }
	 );
    },
    handleFileUpload(){
       this.file = this.$refs.file.files[0];
       let formData = new FormData();
       formData.append('file', this.file);
       formData.append('sort', this.sort);
       
        this.loading = true;
	    this.$store.dispatch("user/savePhoto", formData).then(
	        (answer) => {
	           this.loading = false;
	           if (answer.success){
	           this.formatImage(answer.format);
	           }
	           else{
	            	this.$refs.modal.show = true
 
	           }
	        },
	        (error) => {
	          this.loading = false;
	        }
	 );
       
      },
    
    submitFile(){
	
	
	},
  },
  watch: {
      // при каждом изменении `question` эта функция будет запускаться
      loading(newVal, oldVal) {
        if (newVal) {
          this.showdelete = false;
          this.showplus = false;
    
        }
        else{
        
        }
      },
      image(newVal, oldVal){
			if (newVal!=""){
				this.formatImage(newVal);
			}
      }
    },
}
</script>;