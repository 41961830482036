<template>
<PageHeaderLogged :title="title"   backshow="true" menu="false"/>

  <div class="wrapper graybg top minheight loadingelgraybg" :class="{'loadingel':loading}">{{ content }}
</div>
  
</template>

<script>

export default {
  name: 'InfoView' ,
  data(){
      return {
      	title : '',
      	content: '',
      	loading: true,
      	
      }
    },
   created() {
   this.$store.dispatch("info/get",this.$route.params.id).then(
	        (info) => {
	            this.loading = false;
	            this.title = info.title;
	            this.content = info.content;
	            
	            //if (user.children  != null) children = user.children; 
	        },
	        (error) => {
	        	
	          this.loading = false;
	        }
	 );
  },
  
}
</script>