<template>
     <PageHeaderLogged title="Чаты" backshow="false"  menu="false" @unreadedmessages="handleMessages" :state="state"/>
 
 	  <div class="wrapper graybg chatlist_wrapper minheightwrapper">
  	<div class="chatlist"><UserSmall v-for="user in users" :user="user" goto="true"/></div>
  	<div class="emptlist" v-show="emptyshow">Список чатов  пуст</div>
  </div>
       <BottomPanel activemassage="active"/>
  
</template>
<script>

export default {
  name: 'ChatListView',
  data() {
    	return {
    		users:[],
    		emptyshow:false,
    		state:{from:'chatist',uuid:''},
    
		}
	},
	created() {
    	this.getChatList();
  },
  mounted(){
    		this.setHeight('chatlist_wrapper');
 	this.state.from = 'chatist',
 	this.$store.subscribe(mutation => {
                if (mutation.type === 'setUnreaded') {
                this.getChatList()
                }
            }).bind(this);

	
	},
  methods: {
    getChatList(){
    	
    		this.$store.dispatch("list/getChatList", this.$route.params.id).then(
	        (data) => {
	            this.loading = false;
	            this.users = data;
	            this.emptyshow = this.users.length == 0;
            },
	        (error) => {
	        	
	          this.loading = false;
	        }
	 );
    
    },
   	handleMessages(data){
		 
		if (data.length > 0){
   	   		this.getChatList();
   			/*this.users.forEach(function(el){
   				el.unreaded = 0;
   				data.forEach(function(el2){
					if (el2.uuid == el.uniq) el.unreaded++;
   				})
   				
   			})*/
   		}
   	},
  },
}
</script>