<template>
  	
  	  <div class="wrapper graybg userfull_wrapper">
 		
  	
 	<carousel :items-to-show="1" :mouse-drag="false" :touch-drag="false">
    		<slide v-for="photo in photos" :key="photo">
  					<div class="profile_photo_wrapper " :class="photo.class" :style="{ backgroundImage: 'url(' + photo.x + ')' }"></div>   
    		</slide>
    		  <template #addons>
      <Pagination/>
      <Navigation/>
 
    </template>
 
	</carousel>
 	
 	<div class="profile_title_username  loadingel">{{ username }}</div>
	<ul class="profile_info">
	<li><div class="loadingel">{{ height }} см</div></li>
	<li><div class="loadingel">Дети: {{ children }}</div></li>
	</ul> 
	<div class="writemessage" @click="viewChat"></div> 
	</div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination,Navigation } from 'vue3-carousel'

export default {
	name: 'UserFull', 
	props:{
		user:{
		    required: true,
		  }
	},
	components: {
    Carousel,
    Slide,
    Pagination,Navigation
  },
	data: function () {
    return {
     loading:true,
      children: 'children',
      username: 'username',
      id: -1,
      height: 'height',
      photos:[],
    }
  },
  methods: {
	  
	  viewChat(){
	  	this.$router.push('/chat/'+this.id);
	  }
  },
  mounted(){
     this.loading = false;

	 this.username = this.user.name +', '+this.user.age;
	            this.height = this.user.height == null ? '170 см' : this.user.height;
	            this.children = this.formatChildren(this.user.children);
	            if (this.user.photos!=undefined)
		            this.photos = this.user.photos;
	            if (this.user.photo!=null)
		            this.photos.unshift(this.user.photo)
	            if (this.photos.length == 0){
	            	this.photos.push({class:'nophoto'});
	            }
	            
	            this.id = this.user.uniq;

  },
  
    watch: {
    loading: ()=>{$(".loadingel").removeClass('loadingel');}
   }
 }
 </script>