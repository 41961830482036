import axios from 'axios';
import mixin from './../plugins/mixin';

import authHeader from './auth-header';


class ListService {
  apiUrl = mixin.methods.config('apiUrl') + 'list/'

  getProfiles(filter) {
    return axios.post(this.apiUrl + 'getprofiles', filter, { headers: authHeader() }).then(response => {
    	    return response.data;
    });
  }
  getChat(uuid) {
	    return axios.post(this.apiUrl + 'getchat',{uuid:uuid}, { headers: authHeader() }).then(response => {
	    	if (response.data.success == 1) {
	            return response.data.data;
	            
	        }
	    });
  }
  getChatList() {
	    return axios.post(this.apiUrl + 'getchatlist',{}, { headers: authHeader() }).then(response => {
	    	if (response.data.success == 1) {
	            return response.data.users;
	            
	        }
	    });
}
  getPlacesList() {
	    return axios.post(this.apiUrl + 'getplaceslist',{}, { headers: authHeader() }).then(response => {
	    	if (response.data.success == 1) {
	            return response.data.places;
	            
	        }
	    });
}
  checkUpdates(){
	  return axios.post(this.apiUrl + 'checkupdates',{}, { headers: authHeader() }).then(response => {
	    	if (response.data.success == 1) {
	            return response.data.data;
	            
	        }
	    });
  }
  sendMessage(message){
	  return axios.post(this.apiUrl + 'sendmessage',message, { headers: authHeader() }).then(response => {
	     	
		  if (response.data.success == 1) {
	            return response.data.data;
	            
	        }
	    });
  }
  setReaded(message){
	  return axios.post(this.apiUrl + 'setreaded',message, { headers: authHeader() }).then(response => {
	    	if (response.data.success == 1) {
	            return response.data.data;
	            
	        }
	    });
  }
    
}

export default new ListService();