<template>
    <PageHeaderLogged title="Чекин" menu="false"/>
 
 <div class="mapcontainer">
 
  <div v-if="errorStr"  class="gettingLocation">
    Извините, мы не смогли определить вашу геолокацию ({{errorStr}})
  </div>
  
  <div v-if="gettingLocation" class="gettingLocation">
    <i>Для продолжения работы приложения нам нужна ваша геолокация</i>
  </div>
  
 
<yandex-map 
	  v-model="map"
      real-settings-location
      :settings="settings"
      width="100%" >
   <yandex-map-default-scheme-layer/>
     <yandex-map-default-features-layer/>
    <yandex-map-listener :settings="{   onClick: mapClick, onUpdate:mapUpdate}"/>
    
   <yandex-map-default-marker
        v-model="defaultMarker"
        :settings="{
            //Здесь вам НУЖНО брать координаты либо из функции onDragMove, либо из маркера, стриггерив реактивность
            //Яндекс при выполнении функции .update зачем-то подставляет оригинальные координаты, хотя они не менялись =(
            coordinates: defaultMarker ? defaultMarker.coordinates : center,
        }"
    />
    <yandex-map-controls :settings="{position:'right'}">
	<yandex-map-control-button :settings="{ onClick: arrowClick }" ref="geobutton">
            <div ref="arrow">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.385 12.42l16.01-7.614a.6.6 0 0 1 .8.8l-7.616 16.009a.6.6 0 0 1-1.11-.068l-2.005-6.012-6.01-2.003a.6.6 0 0 1-.069-1.111z" fill="currentColor"></path></svg>
        </div>
        <div ref="clock" style="display:none;">
        <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24"  viewBox="0 0 64 64"><circle cx="28.76" cy="60" r="1"/><path d="M12.69,61H25.76a1,1,0,1,0,0-2H12.69a1,1,0,0,1-1-1V55.45a1,1,0,0,1,1-1H51.31a1,1,0,0,1,1,1V58a1,1,0,0,1-1,1H32.76a1,1,0,1,0,0,2H51.31a3,3,0,0,0,3-3V55.45a3,3,0,0,0-3-3H50.19A23,23,0,0,0,37.48,32c4.63-2.37,12.31-8.17,12.71-20.43h1.12a3,3,0,0,0,3-3V6a3,3,0,0,0-3-3H38.24a1,1,0,0,0,0,2H51.31a1,1,0,0,1,1,1V8.55a1,1,0,0,1-1,1H12.69a1,1,0,0,1-1-1V6a1,1,0,0,1,1-1H31.24a1,1,0,0,0,0-2H12.69a3,3,0,0,0-3,3V8.55a3,3,0,0,0,3,3H48.21c-.36,10.64-6.48,16-11.65,18.65a2,2,0,0,0,0,3.6c5.17,2.64,11.29,8,11.65,18.65H15.79c.36-10.64,6.48-16,11.65-18.65a2,2,0,0,0,0-3.6A20.48,20.48,0,0,1,16,13.71a1,1,0,0,0-2,.23A22.57,22.57,0,0,0,26.52,32c-4.63,2.37-12.31,8.17-12.71,20.43H12.69a3,3,0,0,0-3,3V58A3,3,0,0,0,12.69,61Z"/><circle cx="35.24" cy="4" r="1"/><path d="M41.32,41.32a1,1,0,0,0,.08-1.4,0,0,0,0,0,0,0A1,1,0,0,0,40,39.84a1,1,0,0,0,.67,1.74A1,1,0,0,0,41.32,41.32Z"/><path d="M31.41,35.49s0,0,0,0a1,1,0,0,0-.25-1.38,1,1,0,0,0-1.38.23,0,0,0,0,0,0,0,1,1,0,0,0-.17.75,1,1,0,0,0,.41.64,1,1,0,0,0,.58.19A1,1,0,0,0,31.41,35.49Z"/><path d="M35.65,28.42a20,20,0,0,0,6-4.62,1,1,0,0,0,.14-1.1,1,1,0,0,0-1-.56,135.89,135.89,0,0,1-17.7-.05,1,1,0,0,0-1,.55,1,1,0,0,0,.13,1.1,19.78,19.78,0,0,0,6.05,4.68,4,4,0,0,1,2.1,2.86,1,1,0,0,0,1,.83,6,6,0,0,0,3.26,5.25,19.21,19.21,0,0,1,3.1,2,1,1,0,0,0,.61.21,1,1,0,0,0,.79-.38,1,1,0,0,0-.17-1.4,20.12,20.12,0,0,0-3.42-2.18,4,4,0,0,1,0-7.16ZM32,29.54a5.94,5.94,0,0,0-2.74-2.9,18.81,18.81,0,0,1-3.62-2.4c4.28.21,8.57.22,12.67,0a19.21,19.21,0,0,1-3.57,2.36A5.94,5.94,0,0,0,32,29.54Z"/><path d="M28.75,37.63a1,1,0,1,0-1-1.75,18.43,18.43,0,0,0-9.67,13.4,1,1,0,0,0,.22.81,1,1,0,0,0,.76.36H44.9a1,1,0,0,0,1-1.17,19.26,19.26,0,0,0-2-5.93,1,1,0,0,0-1.36-.4,1,1,0,0,0-.4,1.36,17.15,17.15,0,0,1,1.58,4.14H20.33A16.41,16.41,0,0,1,28.75,37.63Z"/></svg>
        </div>
        </yandex-map-control-button>
    </yandex-map-controls>
    
  </yandex-map>
</div>
<div class="checkin_wrapper">
	<div class="title">{{title_question}}</div>
	<ul class="placeinfo">
		<li class="placename"></li>
		<li class="placecategory"></li>
		<li class="placeaddress"></li>
		
	</ul>
		<div class="fromlist" v-if="fromlist">место из вашего списка</div>
		<div class="checkin_buttons_wrapper">
			<div><button name="" @click="closeCheckinWrapper" class="closecheckin"></button></div>
			<div><button @click="startCheckIn" class="startcheckin"></button></div>
		</div>
</div>
	<div class="distancedialog" >
		<div class="distancedialog_wrapper" @click="closeDistanceWrapper">
			<div><span class="message">Вы должны находиться не более <span class="meters"></span> от выбранного места </span><div class="close"></div></div>
		</div>
	</div>

<modal-window ref="modalneed">
		<template v-slot:title>
                <h3 class="modal-title">Геолокация</h3>
        </template>
 		<template v-slot:body>Необходимо разрешить геолокацию на вашем устройстве</template>
 	    <template v-slot:footer-okbtn>
        </template>
	</modal-window>

     <BottomPanel activemap="active"/>


 
</template>

<script>

import ModalWindow from '../components/modal-window.vue'


import {   YandexMapControlButton, YandexMapControls, YandexMapGeolocationControl, YandexMap, YandexMapDefaultSchemeLayer, YandexMapListener , YandexMapDefaultFeaturesLayer,YandexMapDefaultMarker} from 'vue-yandex-maps';



export default {
  name: 'MapView',
  components: {ModalWindow, YandexMapControlButton, YandexMapControls, YandexMapGeolocationControl, YandexMap, YandexMapDefaultSchemeLayer, YandexMapListener, YandexMapDefaultFeaturesLayer,YandexMapDefaultMarker},
  
  props: {
    msg: String
  },
  data() {
    	return {
    	 map : null,
    	 location:null,
  		  gettingLocation: false,
  		  errorStr:null,
  		  settings : { location: { center: [39.706640, 47.274677], zoom: 15,}},
  		  mapHeight : '500px',
  		  selectedPoint : null,
  		  title_success : 'Чекин успешно выполнен',
		  title_question : 'Зачекиниться здесь?',
		  defaultMarker : {coordinates:[0, 0]},
		  moveAfterGetLocation:false,
		  minMeters : 50,
		  places:[],
		  fromlist:false,
		}
	},
	  methods: {
	  	showgetLocation(){
		  	$(this.$refs.arrow).hide();
	     	$(this.$refs.clock).show();
	  	},
	  	hidegetLocation(){
	 	  	$(this.$refs.arrow).show();
	     	$(this.$refs.clock).hide();
	 	},
	  	
	  	arrowClick(){
	  		this.showgetLocation();
			this.getlocation();
			this.moveAfterGetLocation = true;
	  	},
	  	init(){
	  		let id = this.$route.params.id;
	  		this.$store.dispatch("user/getPlaceInfo", id).then(
			        (data) => {
	    		        this.loading = false;
	        		    	if (data.success == 1){
	    		        			this.suggest(data.place.address + ', '+data.place.name);	
	       		    				this.settings.location.center = data.place.coord;
	       		    				this.settings.location.zoom = 20;
 	
	        		    	}
				        },
				        (error) => {
				          this.loading = false;
				        }
				 );
	  	},
	  	
	  	getlocationSuccess(result){
	  	  this.hidegetLocation();	
  		   this.location = result.location;
           if (this.moveAfterGetLocation)
	       {
	           this.settings.location.center = [this.location.coords.longitude, this.location.coords.latitude];
	       }
	   		   this.defaultMarker =  {coordinates:[this.location.coords.longitude, this.location.coords.latitude]}
	       this.updateUserLocation({coords:{latitude:this.location.coords.latitude, longitude:this.location.coords.longitude}} );

	       this.gettingLocation = false;
	      if (this.selectedPoint!=null){
	      	this.checkIn();
	      }
	      this.moveAfterGetLocation=false;
  	},
	   	getlocationError(err){
			if (err.code == 1){
				this.closeDistanceWrapper();
	   		  	
	   		  	this.$refs.modalneed.show = true
	  
			}
			
			this.hidegetLocation();	
		    this.errorStr = err.message;
		    this.gettingLocation = false;
            this.clearUserLocation();
     		this.defaultMarker =  {coordinates:[0, 0]};
				$(".bb.map.active").removeClass('green');	 
				
				
	       if (this.selectedPoint!=null){
	       	this.closeDistanceWrapper();
	       }
		
	   	},
	 
	  	getlocation(){
	  	 this.gettingLocation = true;
         this.getlocationMixin(this.getlocationSuccess, this.getlocationError);
		},
	  
	    closeCheckinWrapper(){
	    	this.selectedPoint = null;
	    	$(".checkin_wrapper").hide();
 			$(".checkin_wrapper .title").html(this.title_question);
 			$(".checkin_wrapper").removeClass('checkin_buttons_wrapper_ready');
  	
	    },
	    closeDistanceWrapper(){
	    	$(".distancedialog").removeClass('showdistance');
 
	    },
	    showdistanceDialog(message){
	    	$(".distancedialog .message").html(message);
	    	$(".distancedialog").addClass('showdistance');
	    },
	    suggest(adress, coord){
	    	this.$store.dispatch("map/suggest",{coord:coord, adress:adress}).then(
	    	      (data) => {
	    	      	if (data != undefined) this.openCheckInWnd(data);
			
	    		  }
			)
	    },
		mapUpdate(event){
	        localStorage.setItem('mapsettings', JSON.stringify(event.location));
	        this.settings.location.center = event.location.center;
 			this.settings.location.zoom = event.location.zoom;
		},
		
	    mapClick(object){
	    		try{
	    			this.closeCheckinWrapper();
	    			let coord = object.entity.geometry.coordinates;
		    		let adress = object.entity.properties.name;
					this.suggest(adress, coord);
					
	    		}
	    		catch(e){
//		    		console.log(e);
	    		
	    		}
  		},
  		startCheckIn(){
  			
  			//this.arrowClick();
  	//	 	this.showgetLocation();
			this.getlocation();
	
  		},
  		checkIn(){
  				this.$store.dispatch("user/checkIn", this.selectedPoint).then(
			        (data) => {
	    		        this.loading = false;
	        		    	if (data.success == 1){
			            		this.successCheckIn();
	    		        	}
	    		        	if (data.success == 0){
	    		        		this.closeCheckinWrapper();
	    		        		
	    						this.showdistanceDialog(data.msg);
	    		        	}
			            	
				        },
				        (error) => {
	        			  alert('Ошибка регистрации места');	
				          this.loading = false;
				        }
				 );
  		
  		},
  		successCheckIn(){
  			$(".checkin_wrapper .title").html(this.title_success);
			$(".checkin_wrapper").addClass('checkin_buttons_wrapper_ready');
  	
  		},
  		checkMeters(l1){
  		 let loc = this.getDistanceFromLatLonInMeters(this.location.coords.longitude,this.location.coords.latitude, l1[0],l1[1]);	
  		 if (loc > this.minMeters) return false;
	  		 return true;
  		},
  		openCheckInWnd(ff){
  			
  			this.selectedPoint = ff;
  			$(".checkin_wrapper").show();
  			$(".placeinfo .placename").html(ff.properties.CompanyMetaData.name);
  			$(".placeinfo .placecategory").html(ff.properties.CompanyMetaData.Categories[0].name);
  			let prop = ff.properties.description.replace(', Россия','');
  			$(".placeinfo .placeaddress").html( prop);
  			this.fromlist = false;
  			for(let i=0;i<this.places.length;i++){
  				if (ff.properties.CompanyMetaData.id == this.places[i].y) this.fromlist = true;
	  				
  			}
  		}
	},
	mounted(){
		let h = $(window).height() - $(".header").outerHeight();
		this.setHeight('mapcontainer');
 	    
 	    this.$store.dispatch("list/getPlacesList").then(
	        (data) => {
//	        	alert(data);
	            this.places = data;
            },
	        (error) => {
	        	
	          this.loading = false;
	        }
	 );
 	    
 		
	 
//		$(".mapcontainer").css('height', h);
	},
	created() {
		if (localStorage.getItem('mapsettings')!=undefined){
 			let settings = JSON.parse(localStorage.getItem('mapsettings'));
 			this.settings.location.center = settings.center;
 			this.settings.location.zoom = settings.zoom;
 		}
 		this.init()
		//this.getlocation();
  },
    watch: {
   }
}

</script>
<style scoped>
  .marker {
    position: relative;
    width: 20px;
    height: 20px;
    background: #ff0000;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    text-align: center;
    color: #fff;
    font-weight: bold;
    line-height: 20px;
  }
</style>