<template>
<PageHeaderLogged :title="title"   backshow="true" menu="false"/>

  <div class="changeemailcontrol" v-show="changeemailcontrol">
  <div class="wrapper graybg top minheight" >
  <div class="reg_row reg_row_center" v-show="emailpanel1">
    
		      <div class="form-title">Введите новый Email</div>	
				<input type="email" name="email"  class="form-input " :class="{ 'form-input-error': hasemailerror }" v-model="email" @click="inputfocus">
				<div class="form-input-error">{{ emailerroremail }}</div>    
				<div class="field_help">На указанный Ящик придет код для смены вашей Электронной почты</div> 
	</div>
	
	<div class="reg_row reg_row_center" v-show="emailpanel2">
    
		      <div class="form-title">Введите код из письма</div>	
				<input type="text" name="code"  class="form-input " :class="{ 'form-input-error': hascodeerror }" v-model="code" @click="inputfocus">
				<div class="form-input-error">{{ codeerrorcode }}</div>
				<div class="repaetsec" :class="{ 'showtimer': showtimer }" >Повторно можно будет отправить через {{lastsec}} сек</div>
				<a class="senagain" :class="{ 'senagainshow': senagainshow }" href="javascript:void(0)" @click="requestToChangeEmailStep1">Отправить повторно</a>    
	</div>
  <div class="reg_row reg_row_center" v-show="emailpanel3">
  		Email успешно изменен
   </div>
  
  </div>
   
  <div class="button_wrapper button_wrapper_onebutton">
		 <button @click="clickStepEmail" class="button green">{{ button_next_email}}</button>
 </div>
 </div>
 
  <div class="changeemailcontrol" v-show="changeepwdcontrol">
 	 <div class="wrapper graybg top minheight" >

	<div class="reg_row">
        <div class="form-title">Старый пароль</div>	
		<div class="password_wrapper">		
			<input type="password" name="oldpassword" class="form-input " :class="{ 'form-input-error': hasoldpwderror }" v-model="oldpassword" @focus="inputfocus">
			<a href="javascript:void(0)" class="eye_close" @click="toggle_password"></a>
		</div>
						<div class="form-input-error">{{ oldpwderror }}</div>    
		
	</div>
	<div class="reg_row">
        <div class="form-title">Новый пароль</div>	
		<div class="password_wrapper">		
			<input type="password" name="newpassword" class="form-input " :class="{ 'form-input-error': hasnewpwderror }" v-model="newpassword" @focus="inputfocus">
			<a href="javascript:void(0)" class="eye_close" @click="toggle_password"></a>
		</div>
						<div class="form-input-error">{{ newpwderror }}</div>   
						<div class="form-input-success">{{ passwordchanged }}</div>   
						 
		
	</div>



     </div>
		  <div class="button_wrapper button_wrapper_onebutton">
			 <button @click="clickChangePwd" class="button green">Изменить</button>
 	  	  </div>
 </div>
 
</template>

<script>

export default {
  name: 'SettingsView' ,
  data(){
      return {
      	title : 'Изменить Email',
      	emailerroremail:'',
      	hasemailerror:false,button_next_email:'Получить код',
      	email:'',
      	changeEmailStep:1,emailerrorcode:'',
      	hascodeerror:false,
      	emailpanel1:true,
      	emailpanel2:false,
      	emailpanel3:false,
      	lastsec:0,showtimer:false,senagainshow:false,changeemailcontrol:true,changeepwdcontrol:false,
      	hasoldpwderror:false, hasnewpwderror:false, oldpwderror:'',newpwderror:'',
      	oldpassword:'',newpassword:'',passwordchanged:'',
      }
    },
   created() {
   	 var c = this.$router.currentRoute.value.path;
   	 if (c == '/changeemail'){
   	 		//check if sended request
   	 		let sended = localStorage.getItem('requestToChangeEmail');
	        if (sended!=undefined)
			{
				this.requestToChangeEmailStep2();
				this.startTimer();
			}  	 		
   	 }
   	 if (c == '/changepwd'){
   	  	this.title = 'Изменить пароль',
    	this.changeemailcontrol = false;
   	 	this.changeepwdcontrol= true;
   	 }
   
  },
  methods: {
    clickChangePwd(){

		if (!this.checkPassword(this.newpassword) || this.newpassword.trim().length<8) {
			this.hasnewpwderror = true;
			this.newpwderror = this.checkPasswordMessage();
			return;
		}
		this.$store.dispatch("user/changepwd",{newpwd:this.newpassword,oldpwd:this.oldpassword}).then(
	        (answer) => {
	           this.loading = false;
	           if (answer.success){
	              	this.passwordchanged="Пароль успешно изменен";
	              	setTimeout(function(){
	              		this.$router.push('/profile');
	              	
	              	}.bind(this), 2000);
	           }
	           else{
	            	this.hasoldpwderror =true;
	            	this.oldpwderror = answer.msg;
 
	           }
	        },
	        (error) => {
	          this.loading = false;
	        }
	 	);
    },
    toggle_password(el){
		var jel = $(el.target);
		jel.toggleClass('eye_open');
		if (jel.hasClass('eye_open')){
			jel.parents('.password_wrapper').find('input[type=password]').attr('type','text');	
		}
		else
		{
			jel.parents('.password_wrapper').find('input[type=text]').attr('type','password');	
			
		}
		return false;
	},
    getLastSec(){
			let sended = localStorage.getItem('requestToChangeEmail');
			var a = new Date(); // Current date now.
			var b = new Date(sended); // Start of 2010.
			var d = Math.ceil((b-a) / 1000); // Difference in milliseconds.
			if (d<0) return 0;
			return d;
    },
     startTimer(){
        this.intervalid1 = setInterval(function(){
        this.lastsec = this.getLastSec();
        if (this.lastsec == 0 ){
			this.senagainshow=true;this.showtimer=false;
        }
        else
        	this.showtimer = true;
   	 
 	   }.bind(this), 1000);
     },
     inputfocus(el){
     if (el.target.name == 'email'){
			this.emailerroremail= '';
			this.hasemailerror = false;
			return;
		}
	 if (el.target.name == 'code'){
			this.codeerrorcode= '';
			this.hascodeerror = false;
			return;
		}
	 if (el.target.name == 'newpassword'){
			this.newpwderror= '';
			this.hasnewpwderror = false;
			return;
		}
	 if (el.target.name == 'oldpassword'){
			this.oldpwderror= '';
			this.hasoldpwderror = false;
			return;
		}	
	},
    clickStepEmail(){
     this.requestToChangeEmail();
    },
    requestToChangeEmailStep1(){
     localStorage.removeItem('requestToChangeEmail');
       	this.emailpanel1 = true;
  		this.emailpanel2 = false;
  		this.changeEmailStep = 1;
		this.button_next_email = 'Получить код';
     
  },
    requestToChangeEmailStep2(){
    	this.emailpanel1 = false;
  		this.emailpanel2 = true;
  		this.changeEmailStep = 2;
		this.button_next_email = 'Завершить';
		this.senagainshow = false;
    },
    requestToChangeEmail(){
     	if (this.changeEmailStep == 3){
     		this.$router.push('/profile');
	    }
       if (this.changeEmailStep == 1){
    	this.$store.dispatch("user/requestChangeEmail",{email:this.email,step:1}).then(
	        (answer) => {
	           this.loading = false;
	           if (answer.success){
	              var t = new Date();
	              t.setSeconds(t.getSeconds() + 30);
	           	  localStorage.setItem('requestToChangeEmail', t);
	           	  this.requestToChangeEmailStep2();
	           	  this.startTimer();
	           }
	           else{
	            	this.hasemailerror =true;
	            	this.emailerroremail = answer.msg;
 
	           }
	        },
	        (error) => {
	          this.loading = false;
	        }
	 	);
	 }
	  if (this.changeEmailStep == 2){
    	this.$store.dispatch("user/requestChangeEmail",{code:this.code,step:2}).then(
	        (answer) => {
	           this.loading = false;
	           if (answer.success){
	             localStorage.removeItem('requestToChangeEmail');
      
	              this.emailpanel1 = false;
      			  this.emailpanel2= false;
   		   	      this.emailpanel3 = true;
	              this.button_next_email = 'Закрыть';
	              this.changeEmailStep = 3;
	            	
			  }
	           else{
	            	this.hascodeerror =true;
	            	this.codeerrorcode = answer.msg;
 
	           }
	        },
	        (error) => {
	          this.loading = false;
	        }
	 	);
	 }
    },
  }
  
}
</script>