<template>
     <PageHeaderLogged title="" backshow="true"  menu="false"  :state="state"/>
 
 	  <div class="wrapper graybg chat_wrapper">
  	 	<UserSmall v-for="user in users" :user="user"/>
 
  <div class="chat-container">
    <div  class="chat-window">
      <div class="messages-container">
          <div v-for="(val, index) in messages">
              <div class="sepa_wrapper" v-show="val.sepa!=''"><div class="sepa">{{ val.sepa }}</div></div>
        	  <div class="usermessage" :class="[val.who === 'left' ? 'left-bubble' : 'right-bubble']">
              <div class="innner"><span class="m">{{ val.message }}</span>
               	<span class="t">{{ val.time }}</span></div>
          </div>
          </div>
        <div class="scrollTome"></div>
      </div>
      <div class="chat-input">
          <form @submit.prevent="handleMessageSubmit(text)">
          <!-- Use v-model directive to bind the text input to the 'text' variable -->
          <input type="text" v-model="text" placeholder="Написать сообщение..." required/>
          <button type="submit"></button>
          </form>
      </div>
    </div>
  </div>
  </div>
</template>
<script>

export default {
  name: 'ChatView',
  data() {
    	return {
    		users:[],
    		messages:[],text:'',
    		state:{from:'chat',uuid:''},
    		unsubscribeNewMessages:'',
    	
		}
	},

	beforeDestroy() {
    alert(this.unsubscribeNewMessages());
  },
	mounted() {
	 		this.setHeight('messages-container');
 
    this.$store.dispatch("list/getChat", this.$route.params.id).then(
	        (data) => {
	            this.loading = false;
	            this.users.push(data.user);
	            this.state.uuid = data.user.uniq;
	            this.messages = data.messages;
				
            },
	        (error) => {
	        	
	          this.loading = false;
	        }
	 );
	 this.unsubscribeNewMessages = this.$store.subscribe(mutation => {
                if (mutation.type === 'setUpdateObject') {
                			console.log(mutation.payload);
						   	this.handleMessages(mutation.payload.ne)
						
                }
     }).bind(this);
	 
  },
  
  updated(){
		const el = $('.messages-container .usermessage')[$('.messages-container .usermessage').length-1];
        if (el) {
      // Use el.scrollIntoView() to instantly scroll to the element
      el.scrollIntoView({behavior: 'auto'});
  }
  },
  methods: {
    setReaded(messageId){
    	this.$store.dispatch("list/setReaded", {message:messageId}).then(
	        (data) => {
	        
	        },
	        (error) => {
	        
	        }
	 );
    },
   	handleMessages(data){
   		console.log('chat view handleMessages')
   		console.log(data.length);
   		if (data.length > 0){
   			for(let i = 0;i<data.length;i++){
   				  	if (this.state.uuid == data[i].whoid){
	   				    this.messages.push(data[i]);
		    			this.setReaded(data[i].messageid);	
	    			}
   			}
   		}
   	},
    scrollToElement() {
    const el = $('.messages-container .usermessage')[$('.messages-container .usermessage').length-1];
    if (el) {
      el.scrollIntoView({behavior: 'auto'});
    }
  },
   	handleMessageSubmit(message){
   	this.$store.dispatch("list/sendMessage", {uuid:this.$route.params.id,message:message}).then(
	        (data) => {
	            this.loading = false;
	            this.messages.push(data.message);
	            this.scrollToElement();
	            this.text='';
	       
	        },
	        (error) => {
	        	
	          this.loading = false;
	        }
	 );
   	
 	}
  },
}
</script>