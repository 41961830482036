<template>
<div class="menu_panel">
<div class="menu_panel_wrapper1">
	<div class="menu_panel_wrapper">
		<div class="title">Настройки</div>
		<div class="icon_wrapper"><div class="icon close_btn" @click="closemenu"></div></div>
	</div>


<ul>
<li><a class="menu_link"  href="/#info/about">О сервисе</a></li>
<li><a class="menu_link"  href="/#support">Служба поддержки</a></li>
<li><a class="menu_lik" href="/#changeemail">Изменить Email</a></li>
<li><a class="menu_lik" href="/#changepass">Изменить пароль</a></li>
</ul>

<ul>

<li><a class="menu_link"  href="/#info/agree">Пользовательское соглашение</a></li>
<li><a class="menu_link"  href="/#info/politic">Политика конфиденциальности</a></li>

</ul>

<ul class="bolder">
<li><div class="menu_link"  @click="exitaccount">Выход из аккаунта</div></li>

<li><div class="menu_link"  @click="deleteaccount">Удаление аккаунта</div></li>

</ul>

</div>
</div>
<modal-window ref="modal">
<template v-slot:title>
                <h3 class="modal-title">Выйти из аккаунта?</h3>
            </template>
 <template v-slot:body>&nbsp;</template>
 
    <template v-slot:footer-okbtn>
            <button class="modal-footer__button" @click="exitFromAccount">    Выйти  </button>
            </template>
       
 
</modal-window>


<modal-window ref="modaldelete">
<template v-slot:title>
                <h3 class="modal-title">Удаление аккаунта</h3>
            </template>
 <template v-slot:body>Вы действительно хотите удалить аккаунт?</template>
 
    <template v-slot:footer-okbtn>
            <button class="modal-footer__button" @click="deleteAccountAction">    Удалить  </button>
            </template>
       
 
</modal-window>


</template>

<script>
import ModalWindow from '../components/modal-window.vue'

export default {
  name: 'MenuPanel',
  components: {
       ModalWindow
        },
         props: [],
  data(){
      return {
      }
    },
   methods: {
   
   exitFromAccount(){
		this.loading = true;
	 	
		this.$store.dispatch("auth/logout").then(
	        () => {
	            this.loading = false;
	            this.$router.push('/');	
	        	
	        },
	        (error) => {
	          this.loading = false;
	          if (error.response!=undefined)
		          alert(error.response.data.message);
	        }
	      );
	    
	},
   deleteAccountAction(){
		this.loading = true;
	 	
		this.$store.dispatch("user/delaccount").then(
	        () => {
				localStorage.clear();
	        	alert('Ваш аккаунт удален');
	        	location.reload();
	        },
	        (error) => {
	          this.loading = false;
	          if (error.response!=undefined)
		          alert(error.response.data.message);
	        }
	      );
	    
	},
   	exitaccount(){
   	this.$refs.modal.show = true
   	},
   	deleteaccount(){
   	this.$refs.modaldelete.show = true
   	},
   
    closemenu(event) {
    $(".menu_panel").animate({'width':'0'});
   	
   
   }
   	
   
   } 
}
</script>