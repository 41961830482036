<template>
<div class="birthdate_wrapper">
     	<select class="form-input form-input-select" name="date" v-model="date.day">
     		<option value="1">1</option>
     		<option value="2">2</option>
     		<option value="3">3</option>
     		<option value="4">4</option>
     		<option value="5">5</option>
     		<option value="6">6</option>
     		<option value="7">7</option>
     		<option value="8">8</option>
     		<option value="9">9</option>
     		<option value="10">10</option>
     		<option value="11">11</option>
     		<option value="12">12</option>
     		<option value="13">13</option>
     		<option value="14">14</option>
     		<option value="15">15</option>
     		<option value="16">16</option>
     		<option value="17">17</option>
     		<option value="18">18</option>
     		<option value="19">19</option>
     		<option value="20">20</option>
     		<option value="21">21</option>
     		<option value="22">22</option>
     		<option value="23">23</option>
     		<option value="24">24</option>
     		<option value="25">25</option>
     		<option value="26">26</option>
     		<option value="27">27</option>
     		<option value="28">28</option>
     		<option value="29">29</option>
     		<option value="30">30</option>
     		<option value="31">31</option>
     	</select>
     	
     	<select class="form-input form-input-select" name="month" v-model="date.month">
	     	<option value="1">Январь</option>
	     	<option value="2">Февраль</option>
	     	<option value="3">Март</option>
	     	<option value="4">Апрель</option>
	     	<option value="5">Май</option>
	     	<option value="6">Июнь</option>
	     	<option value="7">Июль</option>
	     	<option value="8">Август</option>
	     	<option value="9">Сентябрь</option>
	     	<option value="10">Октябрь</option>
	     	<option value="11">Ноябрь</option>
	     	<option value="12">Декабрь</option>
     	</select>
    

     	<select class="form-input form-input-select" name="year" v-model="date.year">
      	 <option v-for="year in years" :value="year.id" >
    {{ year.name }}
  		 </option>
      	</select>
     	
     </div>	
</template>
<script>
let start = new Date().getFullYear() -18;
let end =  new Date().getFullYear() -78;
let yearsArray = [];
for(let i = start ; i> end; i--) yearsArray.push({name:i,id:i});

export default {
	 name: 'Date',
	 props: {
		    dayValue: {
		      type: [Number],
		      required: true,
		    },
		    monthValue: {
			      type: [Number],
			      required: true,
			    },
			    yearValue: {
				      type: [Number],
				      required: true,
				    },
		  },
	 data (){
		    return {
		    	years: yearsArray,
		    	date:{
		    			day: parseInt(this.dayValue),
		    			month: parseInt(this.monthValue),
		    			year: parseInt(this.yearValue),
		    	} 
		    }
	 },
	 mounted(){
		 console.log(this.date);
			
		},
	 methods:{
		 emitDate() {
		      //alert(this);
		    },
	 },
	 watch: {
		  date: {
			     handler(val){
				      this.$emit("update:modelValue", val);
			     },
			     deep: true
			  }
		  },
}
</script>