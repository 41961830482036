import axios from 'axios';
import mixin from './../plugins/mixin';

class AuthService {
    apiUrl = mixin.methods.config('apiUrl') + 'user/'
    login(user) {
    return axios
      .post(this.apiUrl + 'signin', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.success == 1) {
          localStorage.setItem('user', JSON.stringify(response.data.user));
        }
          return response.data.user;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios
    	   .post(this.apiUrl + 'signup', {user})
    	   .then(response => {
    		   console.log(response.data.user);
    		   	if (response.data.user) {
    		   			localStorage.setItem('user', JSON.stringify(response.data.user));
    		   	}

    	   return response.data.user;
        });; 
  }
  regemail(user) {
	  return axios.post(this.apiUrl + 'regemail', {user});
  }
 
  newpwdemail(email) {
	  return axios.post(this.apiUrl + 'newpwdemail',{email:email}).then(response => {
	            return response.data;
	        });
  }
  newpwdemailchange(info) {
	  return axios.post(this.apiUrl + 'newpwdemailchange', info).then(response => {
	            return response.data;
	        });
  }
}

export default new AuthService();