<template>
  	
  	<div class="placeitem_wrapper " :id="place.id" :class="isactive" @click="activate">
 	<div class="wrapper">
 <div class="wrp2">		
 	<div>	<div class="name">{{ place.name }}</div>	
 		<div class="category " >{{ place.category}}</div>	
 		<div class="address " >{{ place.address}}</div>	
  	</div>
  	<div>
  	
  	<div class="buttons_notactive showbuttons" v-if="b1">
  		<div><button @click="checkin" class="button-checkin"></button></div>
  		<div><button @click="match"  class="button-match"></button></div>
  		<div><button @click="delete1"  class="button-delete"></button></div>
  	</div>
	<div class="buttons_active showbuttons" ref="buttons_active" v-if="b2">
  		<div><button @click="checkout"  class="button-checkout"></button></div>
  		<div><button @click="match" class="button-match"></button></div>
  		<div><button @click="delete2" class="button-delete"></button></div>
  	</div></div>
	</div>
	</div>
	</div>
	
	<modal-window ref="modaldelete">
		<template v-slot:title>
                <h3 class="modal-title">Удаление места</h3>
        </template>
 		<template v-slot:body>Вы действительно хотите удалить это место из списка?</template>
 	    <template v-slot:footer-okbtn>
            <button class="modal-footer__button" @click="delete3">    Удалить  </button>
        </template>
	</modal-window>
	
	
</template>
<script>
import ModalWindow from '../components/modal-window.vue'

export default {
	name: 'PlaceItem',
	props:{
		place:{
		    required: true,
		},
		
	},
	  components: {
       ModalWindow
        },
	
	data:function(){
		return {isactive:true,b1:false,b2:false,}
	},
	 mounted(){
	 	this.isactive = this.place.min<30;
	 	if (this.isactive){
		 	 $("div[id="+this.place.id+"]").addClass('active');

	 	}
	 	if (this.place.current == 1){
	 	 	 $("div[id="+this.place.id+"]").addClass('current');
	 	
		}	 	
	 	if (this.place.one == undefined)
		 	 this.showButtons();
	 },
	 methods: {
	 	 activate(){
	 	 	$(".placeitem_wrapper").removeClass('current');
	 	  	 $("div[id="+this.place.id+"]").addClass('current');
  	 	 	
	 	 	this.$store.dispatch("user/setActivePlace", this.place.id).then(
	        (data) => {
	        	
	  	 	 	
            },
	        (error) => {
	          this.loading = false;
	        }
	        )
	 	 	
	 	 },
	 	 loadData(){
	 	 },
  	 	 delete1(){
 		  	this.$refs.modaldelete.show = true
  	 	 },
  	 	 delete2(){
  	 	  	this.$refs.modaldelete.show = true
  	 	 },
  	 	 delete3(){
		 	this.$store.dispatch("user/deletePlace", this.place.id).then(
	        (data) => {
	        	
	        	$("div[id="+this.place.id+"]").html('');
	            this.loading = false;
  	 	 	 	this.$refs.modaldelete.show = false;
  	 	 	 	if (data.active!=undefined){
  	 	 	 		$('div[id="'+data.active+'"]').addClass('current');
  	 	 	 	}
  	 	 	 	
            },
	        (error) => {
	          this.loading = false;
	        }
	 );
  	 	 
  	 	 },
  	 	 checkout(){
  	 	 this.isactive = false;
  	 	 this.showButtons();
  	 	 this.$store.dispatch("user/checkOut", this.place.id).then(
	        (data) => {
	        	$("div[id="+this.place.id+"]").removeClass('active');
	            this.loading = false;
            },
	        (error) => {
	          this.loading = false;
	        }
  	 	 )
  	 	 },
  	 	 checkin(){
  	 	 
  	  		this.$router.push('/checkin/'+this.place.y);
	 
  	 	 },
  	 	 match(){  this.$router.push('/match/'+this.place.y); },
  	 	 showButtons(){
  	 	 	
			this.b1 = !this.isactive;	
			this.b2 = this.isactive;	
 		}
 	}
}
</script>