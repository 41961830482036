<template>

<div class="header">
<div class="header_wrapper">
   <div class="toprofile " :class="{'backshow':backshow=='true' }" > <a href="#" @click="back"></a></div>
    <h1>{{ title }}</h1>
	<a href="#view" class="viewmyprofilebtn"  v-show="iam" >Просмотр</a>
	<div class="icon_wrapper"  >
		<div class="icon setting" :class="{'menuhide':menu}"  @click="openmenu"></div>
	
	</div>
</div>
</div>
<div class="popupMessage" > <span @click="clickPopup()" class="message">{{ popupMessage }}</span> <div class="closepopup" @click="closePopup">X</div></div>
<div class="errorConectServer" v-show="!connected">
	<div class="noconnectmessage">Упс! Незадача <br><span>Невозможно загрузить данные. Возможно возникли проблемы с сервером или сетевым подключением</span></div>
	 <br><br><button class="modal-footer__button" @click="refreshPage()">Обновить подключение</button>
	
</div>
/div>

</template>
<script>

export default {
  name: 'Header',
  emits: ['newmessages', 'unreadedmessages', 'updatelocation'],
  props:{
   title:{type: String},
   backshow:{type: String, default: "false"},
   iam:{type: Boolean, default: false},
   menu:true,
   
  } ,
  
  data(){
      return {
    	 popupMessage:'',
    	 messagePath:'',
    	 messageUser:'',
    	 timerId:-1,
    	 connected:true,
    	 socketMessage:'',popupMessageText:'',
      }
    },
    mounted(){
    },
    created() {
    	//console.log(this.state);
    	this.$store.dispatch("socket/connect").then(()=>{
    		this.connected = true;
//    		alert('xx');
    		
    		
    		this.update();
    	},(error)=>{this.connected = false});
   		if (!this.$store.state.onMessageSubscribe){
   				this.$store.state.onMessageSubscribe = true;
   				this.$store.subscribe(mutation => {
        	        if (mutation.type === 'socket/onMessage') {
        	           
            	    	this.onSocketMessage(mutation.payload)
	
                	}
		        }).bind(this);
		}
	
		
   		
   },
   

   methods: {
   refreshPage(){
   	location.reload();
   },
    update(){
    		this.sendInfo = true;
   			let store = this.$store; 
			this.$store.dispatch("list/checkUpdates").then(
	        (data) => {
	        	console.log(data);
	        	if (data.needcheckcoord){
	        		this.checkCoord(data.checkcoordafter);
	        	}
	            let ne = [];
	            let toEmit=[]
				for(let i=0;i<data.ne.length;i++){
	            	let state = "chatlist";
   				 	if (this.$router.currentRoute.value.path != '/chats/') state = "chat";
   
	            	if (state == 'chat'){
    						if (this.$route.params.id == data.ne[i].whoid){
								
								toEmit.push(data.ne[i]);
    							continue;
    						}
    				}
					ne.push(data.ne[i]);
            	
	            }
	          //  alert(ne.length);
	            store.commit('setUnreaded', data.nr);
	            if (ne.length>0){
	            	this.showPopup(ne);
	            }
	            if (toEmit.length > 0){
	                  this.$emit('newmessages', toEmit);
	            
	            }
	            if (data.nro != null){
	                  this.$emit('unreadedmessages', data.nro);
	            }
	            
	            store.commit('setUpdateObject', {nr:data.nr,nro:data.nro,ne:toEmit});
	          
		    },
	        (error) => {
	        		
	          this.loading = this.sendInfo = false;
	        })
    },
    	getlocationSuccess(result){
           this.updateUserLocation({coords:{latitude:result.location.coords.latitude, longitude:result.location.coords.longitude}});
  	       this.$emit('updatelocation', true);
  	
  	},
	   	getlocationError(result){
		    this.gettingLocation = false;
            this.clearUserLocation();
     	      this.$emit('updatelocation', false);
  		
	   	},
    checkCoord(min){
	    console.log('last min to update '+min);
    	setTimeout(function(){
    			console.log('get user location by timer');
						this.getlocationMixin(this.getlocationSuccess, this.getlocationError);
    	}.bind(this), min * 10000);
    },
    onSocketMessage(message){
            
            console.log(message);
            switch(message.action){
            	case 'NewMessage':
	            	this.update();
            	break;
            	case 'auth':
            		if (!message.success)		
		            {
			            this.socketMessage = 'Ошибка авторизации';
			            this.connected = false;
			            localStorage.removeItem('user');	
						location.href='/';
		            }
		            
            	break;
            }
    
    },
    clickPopup(){
    
    	let  info = $(".popupMessage").data('info');
    
		if(info.path == 'group'){
			this.$router.push('/chats/');
		}
		if(info.path == 'user'){
			this.$router.push('/chat/' + info.user);
		}
    	
    },
    closePopup(){
    	
    	if ($(".popupMessage").hasClass('popping') || $(".popupMessage").hasClass('popped')){
    		$(".popupMessage").removeClass('popping').removeClass('popped');
    		$(".popupMessage").css('top','-100%');
    	}
    	return false;
    },
    showPopup(messages){
	    this.popupMessage = '';
    	if (messages.length == 1){
    		console.log(messages);
    		this.popupMessage = messages[0].whoname+': '+messages[0].message;
    		this.messagePath = 'user';
   		   	this.messageUser = messages[0].whoid;
   	}
    	if (messages.length>1){
    		this.popupMessage = ' у Вас '+messages.length+' новых ' + this.pluralMessages(messages.length);
    		this.messagePath = 'group';
    	}	
    	if (this.popupMessage!=""){
    	$(".popupMessage").data('info', {'path':this.messagePath,'user':this.messageUser});
    	$(".popupMessage .message").html(this.popupMessage);
		    	$(".popupMessage").addClass('popping').animate({'top':'10'}, 1000,function(){
    					$(".popupMessage").removeClass('popping').addClass('popped');
    			});
   		}
    },
    back(){
    
    this.$router.go(-1);
    },
    openmenu(event) {
    
      this.$router.push('/menu');
//    $(".menu_panel").animate({'width':'100%'});
   	
   
   }
   	
   
   } 
}
</script>