<template>
<PageHeaderLogged :title="title"   backshow="true" menu="false"/>

  
   <div class="changeemailcontrol" v-show="firstcontrol">
  
  <div class="wrapper graybg top minheight loadingelgraybg">
  
  
  Добро пожаловать в службу поддержки! Мы готовы помочь Вам решить любые вопросы, связанные с использованием нашего сервиса. А также рассмотрим предложения о сотрудничестве. Заполните форму и отправить нам. Мы ответим на Ваш адрес Email, указанный при регистрации
  
	<div class="reg_row">
          <div class="form-title">Выберите тему поддержки<sup>*</sup></div>	
	<select class="form-input form-input-select" name="theme" v-model="theme" :class="{ 'form-input-error': hasthemeerror }" @focus="inputfocus">
	     	<option value="0" selected>Не выбрано</option>
	     	<option value="2">Вопрос по работе сервиса</option>
	     	<option value="3">Модерация фото</option>
	     	<option value="4">Смена Email</option>
	     	<option value="5">Жалоба</option>
	     	<option value="6">Предложение</option>
	     	<option value="7">Правовые вопросы</option>
	     	<option value="8">Сотрудничество</option>
     	</select>
     		<div class="form-input-error">{{ themeerror }}</div>
			
	</div>
	
	<div class="reg_row">
        <div class="form-title">Содержание<sup>*</sup></div>	
       <textarea name="text" class="form-title" v-model="text" :class="{ 'form-input-error': hastexterror }" @focus="inputfocus"></textarea>   
	   		<div class="form-input-error">{{ textserror }}</div>
	</div>
	
	<div class="reg_row">
        <div class="form-title">Фото (необязательно)</div>	
	<div class="imageuploader " :class="imageuploadersupport"  ref="divcontainer" v-on:click="openFile"> <div class="divloader" v-show="loading"></div> <div v-show="showplus" class="plus"> {{ plus }} </div> </div>
	<input type="file" accept="image/*"  style="display:none;" id="file" ref="file" v-on:change="handleFileUpload()" />
     		<div class="form-input-error">{{ fileerror }}</div>
   
</div>	
</div>
		  <div class="button_wrapper button_wrapper_onebutton">
			 <button @click="sendClick" class="button green">Отправить</button>
 	  	  </div>
</div>
    <div class="changeemailcontrol" v-show="successcontrol">
		  <div class="wrapper graybg top minheight loadingelgraybg">
				Ваше обращение принято! В ближайшее время Вам ответит специалист службы поддержки		  
		  </div>
		  <div class="button_wrapper button_wrapper_onebutton">
			 <button @click="backclick" class="button green">ОК</button>
 	  	  </div>

 </div>
</template>

<script>

export default {
  name: 'SupportView' ,
  data(){
      return {
      	content: '',
      	loading: false,
      	title: 'Служба поддержки',
      	showplus:true,file : '',      	plus : '+',theme : '', text : '',themeerror:'',hasthemeerror:false,textserror:'',hastexterror:false,firstcontrol:true,successcontrol:false
      	
      }
    },
   created() {
   this.theme = '0';
  
  },
  methods: {
    inputfocus(el){
    
    if (el.target.name == 'theme'){
			this.themeerror= '';
			this.hasthemeerror = false;
			return;
		}
	if (el.target.name == 'text'){
			this.textserror= '';
			this.hastexterror = false;
			return;
		}	
    },
    backclick(){
    	this.$router.push('/profile/');
    },
  	sendClick(){
  
  	   let formData = new FormData();
       if (this.file!="")
	       formData.append('file', this.file);
       formData.append('theme', this.theme);
       formData.append('text', this.text);
   
  	   this.$store.dispatch("user/support",formData).then(
	        (info) => {
	        	if (info.errortheme){
	        	this.hasthemeerror = true;
	        	this.themeerror= 'Выберите тему';
	        	
	        	}
	        	if (info.errortext){
	        	this.hastexterror = true;
	        	this.textserror= 'Введите сообщение';
	        	
	        	}
	        	if (info.errorfile){
	        	this.fileerror= 'Выберите файл изображения';
	        	this.file = null;
	        	this.plus = '+'
	        	}
	        	if (info.success == 1){
	        		this.firstcontrol=false;
	        		this.successcontrol=true;
	        		
	        	}
	            this.loading = false;
	        },
	        (error) => {
	        	
	          this.loading = false;
	        }
	 );		
  	},
    openFile(){
    	$(this.$refs.file).trigger('click');
   	this.fileerror= '';
	         },
    handleFileUpload(){
       this.file = this.$refs.file.files[0];
       this.plus = this.file.name;
    }
  }
  
}
</script>