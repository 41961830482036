<template>
<div class="bottompanel_wrapper">
<div class="bottompanel whiteblock ">
<div class="bb profile" :class="activeprofile" @click="profileClick"></div>
<div class="bb map" :class="activemap" @click="mapClick"></div>

<div class="bb users" :class="activeusers" @click="usersClick"></div>
<div class="bb places" :class="activeplaces" @click="placesClick"></div>
<div class="bb message" :class="activemassage" @click="chatsClick"><span  v-show="messages>0">{{ messages }}</span></div>
</div>
</div>
</template>
<script>

export default {
	props : ['activeprofile', 'activemassage', 'activeusers', 'activemap','activeplaces'],
	name: 'BottomPanel', 
	
	data() {
    	return {
    	messages:0,
		}
	},
	mounted(){
	$(".bottompanel_wrapper").width($("#app").width());
	this.messages =this.$store.getters.getUnreadedMessages; 
	let self = this;
	this.$store.subscribe(mutation => {
                if (mutation.type === 'setUnreaded') {
						self.messages =self.$store.getters.getUnreadedMessages; ;	
                }
            }).bind(this);

	
	},
	  methods: {
	  	placesClick(){
	  		this.$router.push('/places/');
	    },
	    chatsClick(){
			this.$router.push('/chats/');
	    
	    },
   		profileClick(){
			this.$router.push('/profile/');
   		},
   		mapClick(){
			this.$router.push('/checkin/');
   		},
	   	usersClick(){
			this.$router.push('/match/');
		}
	}
	
}
</script>