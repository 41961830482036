<template>
    <PageHeaderLogged title="" menu="false"/>
 <div class="cat_list_users_wrapper">
  		  <div class="wrapper graybg chatlist_wrapper minheightwrapper" v-show="emptyshow">
  <div class="emptlist"  >Список пользователeй  пуст</div>
</div>
 <div class="car__list_users">
    <div class="owl-carousel owl-theme" >
		<div class="item" v-for="user in users" :key="user">	 <UserFull v-bind:user="user"  /></div>
    </div>
 </div>
<PlaceItem v-for="place in places" :place="place" />
 </div>


     <BottomPanel activeusers="active"/>
 
</template>

<script>
import { loadScript } from "vue-plugin-load-script";
  


export default {
  name: 'ListUsersView',
components: {
  },  
  props: {
    msg: String
  },
  data() {
    	return {
    		users:[],
    		emptyshow:false,
    		sloaded:false,
    		places:[],
    		
    		
		}
	},
	mounted(){
	

 	//	this.setHeight('chatlist_wrapper');


 
 

	  	
	},
	created() {
 		let filter = {place:-1};
 		if (this.$route.params.place)
	 		filter.place = this.$route.params.place;
 
	   this.$store.dispatch("list/getProfiles",filter).then(
	        (data) => {
	        	if (data.success == 0){
	        		$(".emptlist").html(data.msg);
		            this.emptyshow =  true;
		            return;
	        	}
	            this.loading = false;
	            this.users = data.data.users;
	            data.data.place.one=true;
	            this.places.push(data.data.place);
	            
	            this.emptyshow =  this.users.length == 0;
	            

	            
	        },
	        (error) => {
	        	
	          this.loading = false;
	        }
	 );
  
 
   },
    watch: {
    users: ()=>{  
    			 loadScript("https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js")
    .then(() => {


	            $('.owl-carousel').owlCarousel({items:1,dots:false});


    })
    .catch(() => {
      // Failed to fetch script
    });
    			
	    
    
     }
   }
}
</script>