<template>
  	
  	 <div class="usersmall_wrapper" @click="gotochat">
 	<div class="wt">{{ user.wt }}</div>	
  	
 	<div class="img_counter"><img :src="photo"/><span class="messages" v-show="user.unreaded>0">{{ user.unreaded }}</span></div>   
    <div><div class="profile_title_username  loadingel">{{ username }}</div>
	<ul class="profile_info">
	<li><div class="loadingel">{{ height }} см</div></li>
	<li><div class="loadingel">Дети: {{ children }}</div></li>
	</ul></div> 
	</div>
</template>
<script>

export default {
	name: 'UserSmall', 
	props:{
		user:{
		    required: true,
	    },
	    goto:{
		    required: false,
	    	default:false,
	    }
	},
	data: function () {
    return {
     loading:true,
      children: 'children',
      username: 'username',
      height: 'height',
      photo:'',
      unreaded:0,
    }
  },
  mounted(){
  	 this.loading = false;
	 this.username = this.user.name +', '+this.user.age;
	            this.height =  this.user.height == null ? '170 см' :  this.user.height;
	            this.children = this.formatChildren(this.user.children);
	            if (this.user.photo)
		            this.photo = this.user.photo.s;
				this.unreaded = this.user.unreaded;

  },
  	  methods: {
  	  gotochat(){
  	  	if (this.goto){
  	  		this.$router.push('/chat/'+this.user.uniq);
  	  	}
  	  }
 },
    watch: {
    
    loading: ()=>{$(".loadingel").removeClass('loadingel');},
    user: function(newVal){
    }
   }
 }
 </script>