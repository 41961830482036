<template>
  <div class="header headernotauth">
   <div class="toprofile " :class="{'backshow':backshow=='true' }" > <a href="#" @click="back"></a></div>
    <h1 class="notloggedh1 " :class="{'backshow':backshow=='true' }">{{ datingname }}</h1>
</div>
</template>
<script>

export default {
  name: 'Header',
  props:{
	   backshow:{type: String, default: "false"},
  },
  data(){
      return {
    	  datingname : this.config('name'),
    	  
      }
    },
    methods: {
    	
    	 back(){
    		    let cc = this.$router.currentRoute.value.path;
    		    if (cc == '/intro') return;
    		    this.$router.go(-1);
    		    },
    	
    }
}
</script>