import MapService from '../services/map.service';

export const map = {
		  namespaced: true,
 actions: {
	    suggest({ commit }, params) {
	    	
	    	return MapService.suggest(params).then(
	          answer => {
	            commit('suggest');
	            return Promise.resolve(answer);
	          },
	          error => {
	            commit('suggest');
	            return Promise.reject(error);
	          }
	        );
	      },
  
  },
  mutations: {
	  suggest(state) {
	  },
  }
}