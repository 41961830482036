import SocketService from '../services/socket.service';

export const socket = {
  namespaced: true,
  connected:false,
  
  state:{
  	message:Object,
  	connected:false,
  	
  },
  actions: {
     sendMessage({ commit }, msg) {
    	  return SocketService.sendMessage(msg);
	},
    connect({ commit }) {
	  if (this.state.socket.connected) {
			commit('connectionContinue');
			  
	  }
      return SocketService.connect().then(function(server) {
	 		
			commit('connectedSuccess',server);
		    server.onmessage = function(msg){
		    		commit('onMessage', $.parseJSON(msg.data));
	 
			}
		    
		    return Promise.resolve();
       
	  }).catch(function(error) {
		  console.log(error)
				commit('connectedFalure', error);
	    return Promise.reject(error);
    
		});;	
      
    },
  },
  mutations: {
	  connectionContinue(state){
		  
	  },
	   onMessage(state, message){
	   
		     switch(message.action){
            	case 'Ping':
	    			SocketService.sendMessage({"action":"Pong"});
        		break;
        	}      
	   		state.message = message;
	   },
	   connectedSuccess(state, server){
	   this.connected=true;
	   server.onmessage = function(msg){alert(msg.data)}
 	   state.connected = true;
 		 
	  },
	  connectedFalure(error) {
	  console.log('connection error')
		  this.connected=false;
    },
  }
};
