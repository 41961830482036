import axios from 'axios';
import mixin from './../plugins/mixin';

class InfoService {
    apiUrl = mixin.methods.config('apiUrl') + 'info/'
    
  getinfo(id) {
	  return axios.post(this.apiUrl + 'get', {id:id}).then(response => {
		  if (response.data.success == 1) {
	            return response.data.info;
	            
	        }
          
     // }
      });;
  }
}

export default new InfoService();