<template>
  <PageHeader  backshow="true"/>
  
   <div class="wrapper graybg top">
		<div class="panel1"  v-show="panel1">	  <div class="register_title">Представьтесь</div>
		      <div class="reg_row">
		      <div class="form-title">Укажите свой пол</div>
        <div class="gender_wrapper">
		      		<div class="form_radio_btn" style="display: none;">
		      			<input type="radio" name="gender" value="" id="radio-0" v-model="gender" >
		      			<label for="radio-0">Мужской</label>
					</div>
		      		
		      		<div class="form_radio_btn">
		      			<input type="radio" name="gender" value="1" id="radio-1" v-model="gender" @click="inputfocus">
		      			<label for="radio-1">Мужской</label>
					</div>
		      		<div class="form_radio_btn">
		      			<input type="radio" name="gender" value="0" id="radio-2" v-model="gender" @click="inputfocus">
		      			<label for="radio-2">Женский</label>
					</div>
		      </div>
		   	<div class="form-input-error">{{ gendererror }}</div>    
		   </div>
		   
		       <div class="reg_row">
    
		      <div class="form-title">Ваше имя</div>	
				<input type="text" name="name" value="" class="form-input " :class="{ 'form-input-error': hasusernamerror }" v-model="username" @focus="inputfocus">
				<div class="form-input-error">{{ usernamerror }}</div>    
	</div>
	    <div class="reg_row">
    	      <div class="form-title">Дата рождения</div>	
		    
		      <div class="dat_years_wrapper"> <div> <Date v-model="date" :dayValue="date.day" :monthValue="date.month" :yearValue="date.year"/></div>
		      	<div class="total_year">{{ yearsTotal }}</div>
		      </div>	
		      <div class="form-input-error">{{ yearerror }}</div>
		      </div>
    </div>
    <div class="panel2" v-show="panel2">
    		<div class="register_title">Регистрация</div>
        <div class="reg_row">
        <div class="form-title">Ваш email</div>	
				<input type="email" name="email" value="" class="form-input " :class="{ 'form-input-error': hasemailerror }" v-model="email" @focus="inputfocus">
				<div class="form-input-error">{{ emailerror }}</div>    
</div>
<div class="reg_row">
        <div class="form-title">Придумайте пароль</div>	
		<div class="password_wrapper">		
			<input type="password" name="password" value="" class="form-input " :class="{ 'form-input-error': haspwderror }" v-model="password" @focus="inputfocus">
			<a href="javascript:void(0)" class="eye_close" @click="toggle_password"></a>
		</div>
						<div class="form-input-error">{{ pwderror }}</div>    
		
		</div>
		<div class="reg_row">
			<div class="form_radio" >
		      		<input type="checkbox" value="1" name="savepdwd"  id="radio-savepdwd" v-model="savepwd" checked="checked" >
		      			<label for="radio-savepdwd">Сохранить пароль</label>
				</div>
		</div>		
    </div>
     <div class="panel3" v-show="panel3">
      <div class="register_title">Проверочный код</div>
      <div class="reg_view_wmail">
      <div class="form-title">Мы отправили на ваш емейл</div>	
		<div class="colored_email">{{ email }}	</div>
      <div class="form-title">проверочный код</div>	
</div>
      <div class="reg_row">
    
      <div class="form-title">Введите код из письма</div>	
				<input type="text" name="emailcode" value="" class="form-input " :class="{ 'form-input-error': hasemailcodeerror }" v-model="emailcode" @focus="inputfocus">
				<div class="form-input-error">{{ emailcodeerror }}</div>    
	</div>

	</div> 
    
    <form @submit.prevent="registerUser">
    </form>
  </div>
  
  <div class="button_wrapper button_wrapper_onebutton">
		 <button  @click="toLastStep" class="button green">{{ button_next}}</button>
 
 </div>
</template>
<script>

export default {
	
	name: 'RegistrationView', 
	
	data() {
    return {
      step : 1,
      loading:false,
      date:{day:1,month:1,year:2001},
      birthdate : new Date(),
      yearsTotal:"18 лет" ,	
      username:'',
      years:18 ,	
       password:'',
      gender:'',
      emailcode:'',emailcodeerror:'',
      hasusernamerror:false,
      hasemailerror:false,
      haspwderror:false,
      savepwd:true,
      hasemailcodeerror:false,
      gendererror:'',
      emailerror:'',
      usernamerror:'',
      pwderror:'',
      panel1 : true,
      panel2:false,
      panel3:false,email:'',
      button_next : 'Далее',
      button_finish : 'Зарегистрироваться',
      button_loading : 'Загрузка',
      yearerror:''
        
    };
  },
  beforeMount() {
	  let user = JSON.parse(localStorage.getItem('userState'));
	     
if (user){
		this.gender = 	user.gender;
		this.username = 	user.username;
		if (user.birthdate!=null){
			let bd = new Date(user.birthdate);
		//	alert(bd);
			this.birthdate = bd;
			//this.date.month = bd.getMonth();
			//this.date.day = bd.getDay();
//			alert(this.date.day);
		//	console.log(bd);

			this.date = user.date;
			this.email =user.email;
			this.savepwd =user.savepwd;
			//this.$emit('upda:date',this.date);
		}
		
	}
	//this.step = localStorage.getItem('regStep');
	
},
 mounted(){
    //this.switchStep(this.step);
    this.calcYear(this.date);
    $(".toprofile.backshow").click(function(event){
    	event.stopPropagation()
    	if (this.step == 1){
			this.$router.push('/');    		
    	}
    	if (this.step == 2){
			this. switchStep(1);
    	}
    	if (this.step == 3){
    		
			this. switchStep(2);
    	}
    	return false;
    }.bind(this))
	
},
  methods: {
	  
	  saveUserState(){
	      localStorage.setItem('userState', JSON.stringify(this.getUser()));
	      localStorage.setItem('regStep', this.step);
	//	  this.$store.userState = this.getUser();
				},
	  switchStep(to){
					
		this.step = to;
		switch(parseInt(this.step)){
			case 1:
				this.$store.regStep = to;
		       	this.panel1=true;
	    		this.panel2=false;
	    		this.panel3=false;
			break;
			case 2:
				this.saveUserState();
				this.panel1=false;
	    		this.panel2=true;
	    		this.panel3=false;
			break;
			case 3:
				this.saveUserState();
		       	this.panel1=false;
	    		this.panel2=false;
	    		this.panel3=true;
			break;
		}
	}  ,
	getUser(){
		console.log(this.date);
		this.birthdate = new Date(this.date.year, this.date.month - 1, this.date.day-1);   
   		return {
			username: this.username,
		      email: this.email,
		      gender: this.gender,
		      birthdate: this.birthdate,
		      date: this.date,
		      savepwd: this.savepwd==1,
		  			}
	}  ,
	checkEmail(user){
		if (this.loading) return;
		this.loading = true;
		this.$store.dispatch("auth/regemail", user).then(
	        () => {
	            this.loading = false;
	            this.switchStep(3);
	        	
	        },
	        (error) => {
	          this.loading = false;
	          if (error.response!=undefined)
		          this.emailerror = error.response.data.message;
	          this.hasemailerror = true;
	        }
	      );
	    
	},
	toggle_password(el){
		var jel = $(el.target);
		jel.toggleClass('eye_open');
		if (jel.hasClass('eye_open')){
			jel.parents('.password_wrapper').find('input[name=password]').attr('type','text');	
		}
		else
		{
			jel.parents('.password_wrapper').find('input[name=password]').attr('type','password');	
			
		}
		return false;
	},
	
	inputfocus(el){
		if (el.target.name == 'gender'){
			this.gendererror= '';
			return;
		}
		if (el.target.name == 'email'){
			this.emailerror= '';
			this.hasemailerror = false;
			return;
		}
		if (el.target.name == 'emailcode'){
			this.emailcodeerror= '';
			this.hasemailcodeerror = false;
			return;
		}
		if (el.target.name == 'password'){
			this.pwderror= '';
			this.haspwderror = false;
			return;
		}
		if (el.target.name == 'name'){
					if (this.hasusernamerror){
						this.usernamerror = '';
						this.hasusernamerror = false;
						return;
					}
		}
	} ,
	validEmail: function (email) {
	      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	      return re.test(email);
	    },
	tryReg(){
	    	if (this.emailcode.trim() == ''){
				this.emailcodeerror = 'Введите код из письма';
				this.hasemailcodeerror = true;
				return;
			}
	    	
	    	if (this.loading) return;
			this.loading = true;
			let user = this.getUser()
			user.password = this.password;
			user.emailcode = this.emailcode;
			//console.log(user);
			this.$store.dispatch("auth/register", user).then(
		        () => {
		            this.loading = false;
		            
		            localStorage.setItem('user_email', this.email)
		        	if (this.savepwd)
		        		localStorage.setItem('user_pwd', this.password)
			        		
		    		
		            
		            
		            this.$router.push('/profile');	
		            //this.switchStep(3);
		        },
		        (error) => {
		          this.loading = false;
		          if (error!=undefined)
			          this.emailcodeerror = error.response.data.message;
		          this.hasemailcodeerror = true;
		        }
		      );
		       	
	},
	checkdate(val)
	{
		
	 var day =val.day;
	 var month = val.month-1;
	 var year = val.year;
	 //alert(day);
	 // This instruction will create a date object
	 var source_date = new Date(year,month,day);

	 if(year != source_date.getFullYear())
	{
	   return false;
	}

	if(month != source_date.getMonth())
	{
	  return false;
	}

	if(day != source_date.getDate())
	{
	  return false;
	}

	 return true;
	},
	calcYear(val){
	    var birthDate = new Date(val.year, val.month-1, val.day);  
	    var  otherDate = new Date();
	    var age = (otherDate.getFullYear() - birthDate.getFullYear());
	    if (otherDate.getMonth() < birthDate.getMonth() ||
	        otherDate.getMonth() == birthDate.getMonth() && otherDate.getDate() < birthDate.getDate()) {
	    	age--;
	    }
	    this.yearsTotal = age + ' ' +  this.pluralage(age);
	    this.years = age ;
		if (!this.checkdate(val)){
			this.yearerror = 'Некорректная дата, проверьте дату';	
			
		}
	    if (age <18){
			this.yearerror = 'Сервисом можно пользоваться только 18+ лет';	
		}
	    this.birthdate = birthDate; 
	},
	toLastStep(){
if(this.step == 3){
	    	this.tryReg();
	    	return false;
	    }	
		let step1=true;
		let step2=true;
		if (this.yearerror !="")
		{
			step1=false;
			
		}
		if (this.gender == undefined || this.gender ==''){
			this.gendererror = 'Выберите пол';	
			step1=false;
		}
		if (this.years < 18){
			this.yearerror = 'Сервисом можно пользоваться только 18+ лет';	
			step1=false;
		}
		if (this.username.trim() == ''){
			this.usernamerror = 'Имя не может быть пустым';
			this.hasusernamerror = true;
			step1=false;
		}
		if (this.username.trim().length<1){
			this.usernamerror = 'Имя должно содержать минимум 1 символ';
			this.hasusernamerror = true;
			step1=false;
		}
		if (!step1) return false;
		
		this.panel1=false;
		if (!this.panel2)
		{
            this.switchStep(2);
			return false;
		}

		if (!this.email) {
			step2=false;
			this.hasemailerror = true;
			this.emailerror = 'Email не может быть пустым';
			  } else if (!this.validEmail(this.email)) {
				step2=false;
		        this.hasemailerror = true;
				this.emailerror = 'Неверный формат Email';
	    }
		if (this.password.trim() == ''){
		       this.haspwderror = true;
				this.pwderror = 'Введите пароль';
				return false;
		}
		if (!this.checkPassword(this.password) || this.password.trim().length<8) {
			step2=false;
	        this.haspwderror = true;
			this.pwderror = this.checkPasswordMessage();
			return false;
			
		}
		
		if (!step2) return false;
	
    	this.checkEmail(this.getUser());
		
	}  ,
    registerUser() {
      // Your registration logic here
    }
  },
  
  watch: {
	  loading(nnew, nold){
		  /*
		     button_next : 'Далее',
      button_finish : 'Зарегистрироваться',
      button_loading : 'Загрузка',
  */
		  
		if (nnew){
			$(".button.green").addClass('process').html(this.button_loading);
		}  
		else
		{
			$(".button.green").removeClass('process').html(this.panel3 ? this.button_finish :  this.button_next);
		}		
	  },
	  date: {
		     handler(val){
		    	this.yearerror='';
		    	this.calcYear(val)
		 		    
		     },
		     deep: true
		  }
	  },
};
</script>