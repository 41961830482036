<template>
  <PageHeader  backshow="true"/>
 

 <div  v-show="loginpanel">
   
    <form @submit="login" class="add-form">
  <div class="wrapper graybg top">

 

     <div class="register_title">Вход</div>
 
	<div class="reg_row">
    
		      <div class="form-title">Ваш email</div>	
				<input type="text" name="name"  class="form-input " v-model="email" @click="inputfocus">
				
	</div>
				<div class="form-input-error">{{ erroremail }}</div>    
	
	<div class="reg_row">
    
		      <div class="form-title">Введите пароль</div>	
				
				
				<div class="password_wrapper">		
			<input type="password" name="password" value="" class="form-input " :class="{ 'form-input-error': haspwderror }" v-model="password" @focus="inputfocus">
			<a href="javascript:void(0)" class="eye_close" @click="toggle_password"></a>
		</div>
		
				
	</div>
	
					<div class="form-input-error">{{ errorpwd }}</div>    
	
	
	<div class="reg_row"><a href="javascript:void(0)" class="fogotpwdlink" @click="fogotpassword">Забыли пароль?</a></div>
	

<div class="reg_row">
			<div class="form_radio" >
		      		<input type="checkbox" value="1" name="savepdwd"  id="radio-savepdwd" v-model="savepwd" checked="checked" >
		      			<label for="radio-savepdwd">Сохранить пароль</label>
				</div>
		</div>	

					<div class="form-input-error">{{ error }}</div>    

</div>
   <div class="button_wrapper button_wrapper_onebutton">
		 <button class="button green">Готово</button>
 </div>
  </form>
 
 </div>
 
  <div v-show="fogotpanel">
   <div class="wrapper graybg top minheight">
   
   <div class="reg_row">
    
		      <div class="form-title">Ваш Email</div>	
				<input type="text" name="fogortemail"  class="form-input " :class="{ 'form-input-error': hasfogotemailerror }" v-model="fogotemail" @click="inputfocus">
	</div>
    На вашу электронную почту будет отправлен код для восстановления пароля  
  
  					<div class="form-input-error">{{ errorfogotemail }}</div>    
  
   </div>
    <div class="button_wrapper button_wrapper_onebutton">
		 <button class="button green"  @click="fogotpassword_click">{{ fogot_button_name }}</button>
 </div>
   
   
   </div>
   
   <div class="reg_row" v-show="fogotpanel_finish">
   <div class="wrapper graybg top minheight">
   

	<div class="reg_row">
    
		      <div class="form-title">Введите код из письма</div>	
				<input type="text" name="code"  class="form-input " :class="{ 'form-input-error': hasnewpwderror }" v-model="code" @focus="inputfocus">
	</div>
	<div class="reg_row">
        <div class="form-title">Придумайте новый пароль</div>	
		<div class="password_wrapper">		
			<input type="password" name="newpassword" class="form-input " :class="{ 'form-input-error': hasnewpwderror }" v-model="newpassword" @focus="inputfocus">
			<a href="javascript:void(0)" class="eye_close" @click="toggle_password"></a>
		</div>
						<div class="form-input-error">{{ newpwderror }}</div>    
		
		</div>	


   </div>
    <div class="button_wrapper button_wrapper_onebutton">
		 <button class="button green"  @click="changepassword_click">{{ chnangepassword_button_name }}</button>
 </div>
   
   
   </div>
   
   
  <div class="reg_row" v-show="fogotpanel_finish_ok">
   <div class="wrapper graybg top minheight">
   
		Пароль успешно изменен
	

   </div>
    <div class="button_wrapper button_wrapper_onebutton">
		 <button class="button green"  @click="step0">Войти</button>
 </div>
   
   
   </div>
 
</template>

<script>
export default {
  name: 'LoginView', 
  data(){
      return {
        email : "",
        password : "",
        loading: false,hasfogotemailerror:false,fogotemail:'',
        error : '',
           erroremail : '',errorfogotemail:'',
        errorpwd : '',
        fogotpanel_finish_ok:false,
        savepwd: true,loginpanel : true,fogotpanel:false,fogotpanel_finish:false,
        fogot_button_name: 'Продолжить',chnangepassword_button_name:'Сменить пароль', newpassword:'',code:'',hasnewpwderror:false,newpwderror:"",
      }
    },
	beforeMount() {
		if (localStorage.getItem('user_email')){
			this.email = localStorage.getItem('user_email');
		}
		if (localStorage.getItem('user_pwd')){
			this.password = localStorage.getItem('user_pwd');
		    			
		}
	
	},
    methods: {
    	step0(){
    		this.loginpanel = true;
    		this.fogotpanel_finish_ok = false;
    	},
    	toggle_password(el){
    		var jel = $(el.target);
    		jel.toggleClass('eye_open');
    		if (jel.hasClass('eye_open')){
    			jel.parents('.password_wrapper').find('input[name=password], input[name=newpassword]').attr('type','text');	
    		}
    		else
    		{
    			jel.parents('.password_wrapper').find('input[name=password], input[name=newpassword]').attr('type','password');	
    			
    		}
    		return false;
    	},
    	inputfocus(el){
    		//console.log(el.target.name);
    		//
    		if (el.target.name == 'fogortemail' )
    		{
    			this.errorfogotemail = '';this.error='';
    		}
    		if (el.target.name == 'name' )
    		{
    			this.erroremail = '';this.error='';
    		}
    		if ('password' == el.target.name){
    			this.errorpwd = '';this.error='';
    			
    		}
    		if (el.target.name == 'newpassword' || el.target.name == 'code'){
    	     	this.newpwderror = '';
	        	this.hasnewpwderror =false;
      
    			return;
    		}
    		
    		
    	},
    	changepassword_click:function(){
    		if (!this.checkPassword(this.newpassword) || this.newpassword.trim().length<8) {
    	    	this.newpwderror = this.checkPasswordMessage();
   		    	this.hasnewpwderror = true;
   		    	return;
	    	}
        	this.loading = true;
			this.$store.dispatch("auth/newpwdemailchange", {email:this.fogotemail, code:this.code,password:this.newpassword}).then(
		        (answer) => {
		            this.loading = false;
		            console.log(answer);	
		            if (answer.success == 1){
		        		        this.fogotpanel_finish=false;
		        		        this.fogotpanel_finish_ok = true;
		        		        
		            }
		            if (answer.success == 0){
    		        	this.newpwderror = answer.msg;
    		        	this.hasnewpwderror = true;
		            }
		            
		        },
		        (error) => {
		          this.loading = false;
  		          if (error.response.status == 405)
    		        {
				        alert(error.response.data.message);
	   	        }
		          if (error.response.status != 405 && error.message!=undefined)
		        	  alert(error.message);
		        }
		        
		      );
    		
    		
    		return false;
    	},
    	fogotpassword_click:function(){
    		
    		if (!this.validEmail(this.fogotemail)){
		         this.errorfogotemail = 'Введите Email';
				 return;
			}
    		
    		this.code='';
    		this.newpassword='';

    		
        	this.loading = true;
			this.$store.dispatch("auth/newpwdemail", this.fogotemail).then(
		        (answer) => {
		            this.loading = false;
		            
		            if (answer.success == 1){
		            	this.loginpanel = false;
		        		this.fogotpanel = false;
		        		this.fogotpanel_finish = true;
		        		        	
		            }
		            
		        },
		        (error) => {
		          this.loading = false;
  		          if (error.response.status == 405)
    		        {
				        alert(error.response.data.message);
	   	        }
		          if (error.response.status != 405 && error.message!=undefined)
		        	  alert(error.message);
		        }
		        
		      );
    		
    		
    		return false;
    	},
    	fogotpassword: function(){
    		this.fogotemail = '';
	this.loginpanel = false;
    		this.fogotpanel = true;
    	},
    	validEmail: function (email) {
  	      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  	      return re.test(email);
  	    },
  	    showError:function (message){
	          this.error = message;
	          var self = this;
	          setTimeout(function(){
	        	  self.error ='';
	          }, 1000)

  	    },
  	    
    	login(e){
  	       e.preventDefault()
  	        if (this.loading) return;
			if (!this.validEmail(this.email)){
		         this.erroremail = 'Введите Email';
				 return;
			}
			if (this.password.trim()==""){
				 this.errorpwd = 'Введите пароль';
					 return;
			}
	    	this.loading = true;
			this.$store.dispatch("auth/login", {username:this.email,password:this.password}).then(
		        (user) => {
		            this.loading = false;
		            localStorage.setItem('user_email', this.email);
		        	
		            if (this.savepwd){
			            localStorage.setItem('user_pwd', this.password);
		            }
		            this.$router.push('/profile');
		        },
		        (error) => {
		          this.loading = false;
		          let showError = '';
  		          if (error.response.status == 405)
    		        {
				        showError = error.response.data.message;
	   	        }
		          if (error.response.status != 405 && error.message!=undefined)
		        	  showError = error.message;
		         
		          
		          this.error = showError;
		        }
		        
		      );
		       	
	},
    	
    },
}
</script>