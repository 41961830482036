import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    newpwdemail({ commit }, email) {
        return AuthService.newpwdemail(email).then(
          answer => {
            commit('newpwdemailSuccess');
            return Promise.resolve(answer);
          },
          error => {
            commit('loginFailure');
            return Promise.reject(error);
          }
        );
      },
    regemail({ commit }, user) {
    	return AuthService.regemail(user).then(
    		response => {
        	  console.log(user);
            commit('regemailSuccess');
            return  Promise.resolve(response.data);
          },
          error => {
        	  commit('regemailFailure');
            return Promise.reject(error);
          }
        );
      },
    newpwdemailchange({ commit }, info) {
      	return AuthService.newpwdemailchange(info).then(
      			answer => {
              commit('regemailSuccess');
              return  Promise.resolve(answer);
            },
            error => {
          	  commit('newpwdemailchangeFailure');
              return Promise.reject(error);
            }
          );
        },
    logout({ commit }) {
//      alert("ok");
    	  AuthService.logout();
      commit('logout');
    },
  
    register({ commit }, user) {

      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(user);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    regemailFailure(state) {
        state.status.regemail = false;
      },
      regemailSuccess(state) {
          state.status.regemail = true;
        },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = true;
      state.user = user;

    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};
